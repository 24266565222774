.cardContainer {
  padding: 0.75rem;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
  width: 12rem;
  height: 15rem;
  background-color: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;

  /* ADDED! */
  /* adding link specific extras */
  color: black;
  transition: box-shadow 0.3s;
}
/* ADDED! */
.cardContainer:hover {
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.3);
}
/* ADDED! */
.cCLink {
  text-decoration: none;
}

.cardContainer img {
  height: 100%;
  width: 100%;
}

.cardContainer h3 {
  font-size: var(--font-regular);
}
