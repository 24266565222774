h2 {
  color: var(--blue-regular);
  position: relative;
  width: fit-content;
  font-size: var(--font-large);
  margin-bottom: 0.5rem;
}

h2 > span {
  z-index: 2;
  position: relative;
}

h2::after {
  content: "";
  position: absolute;
  inset: auto 0 0 0;
  height: 3px;
  transform: translateY(-200%);
  z-index: 1;
}
