:root {
  --blue-lightest: hsl(0, 0%, 0%);
  --blue-regular: #38b6ff;
  --blue-dark: #2d2b3d;
  --blue-light: #3db6fc;

  --font-xsmall: 0.8rem;
  --font-small: 0.9rem;
  --font-regular: 1rem;
  --font-large: 1.2rem;
  --font-xlarge: 1.5rem;
}

body,
html {
  margin: 0;
  font-family: Roboto;
  font-size: 100%;
  width: 100vw;
  height: 100vh;
  padding: 0;
  scroll-behavior: smooth;
  background-color: rgb(0, 0, 0);
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: Noto Sans;
  margin: 0;
}

p {
  margin: 0;
}

@media (max-width: 730px) {
  html {
    font-size: 74%;
  }
}

@media (max-width: 575px) {
  html {
    font-size: 70%;
  }
}

@media (max-width: 505px) {
  html {
    font-size: 60%;
  }
}

@media (max-width: 445px) {
  html {
    font-size: 50%;
  }
}

@media (max-width: 375px) {
  .accordion h3 {
    font-size: 0.9rem;
  }
  .accordion img {
    height: 1.5rem;
  }
  .navLinks {
    visibility: hidden;
    display: none;
  }
}

